.page_header {
    width: 100%;
    height: 40px;
    padding: 0px 20px;
    background-color: #444;
}
#pronounSwitch{
    color:#fff;
    position: absolute;
    right: 20px;
    top:4px;
    font-size: 22px;
    text-align: center;
    border: 2px solid #fff;
    width: 30px;
    height:30px;
    border-radius: 4px;
}
#keyBoardBtn{
    color:#fff;
    position: absolute;
    right: 70px;
    top:4px;
    font-size: 22px;
    text-align: center;
    border: 2px solid #fff;
    width: 30px;
    height:30px;
    border-radius: 4px;
}
#hideinput{
    position: absolute;
    left:-200px
}